<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }"
                >首页</el-breadcrumb-item
            >
            <el-breadcrumb-item
                :to="{
                    path: '/pczx_index',
                }"
                >拼词之星管理</el-breadcrumb-item
            >
            <el-breadcrumb-item
                :to="{
                    path: '/pczx_room_manage',
                }"
                >房间管理</el-breadcrumb-item
            >
            <el-breadcrumb-item>选手管理</el-breadcrumb-item>
        </el-breadcrumb>
        <el-page-header @back="goBack"></el-page-header>
        <!-- 筛选 -->
        <el-card style="margin-top: 15px">
            <el-row class="title_row">
                <el-col>
                    <span>数据筛选</span>
                </el-col>
            </el-row>
            <el-form :model="queryForm" label-width="80px" ref="queryFormRef">
                <el-row :gutter="50">
                    <el-col :span="6">
                        <el-form-item label="手机号" prop="phone">
                            <el-input
                                v-model="queryForm.phone"
                                placeholder="请输入"
                                clearable
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="姓名" prop="userName">
                            <el-input
                                v-model="queryForm.userName"
                                placeholder="请输入"
                                clearable
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="是否签到" prop="sign">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                v-model="queryForm.sign"
                            >
                                <el-option
                                    v-for="item in dict_signIn"
                                    :key="item.dictValue"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20" type="flex" justify="end">
                    <el-col :span="2">
                        <el-button
                            type="primary"
                            style="width: 100%"
                            @click="getPlayerList"
                            >查询</el-button
                        >
                    </el-col>
                    <el-col :span="2">
                        <el-button
                            type="primary"
                            style="width: 100%"
                            @click="reset"
                            >重置</el-button
                        >
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <!-- 数据列表 -->
        <el-card style="margin-top: 15px">
            <el-row class="title_row">
                <el-col :span="12">
                    <span class="list_title">选手列表</span>
                </el-col>
            </el-row>
            <el-table
                header-cell-class-name="tableHeaderStyle"
                :data="dataList"
                border
            >
                <el-table-column type="index"></el-table-column>
                <el-table-column
                    label="选手姓名"
                    prop="userName"
                ></el-table-column>
                <el-table-column
                    label="联系方式"
                    prop="phone"
                ></el-table-column>
                <el-table-column label="座位号" prop="sitNum"></el-table-column>
                <el-table-column
                    label="参赛轮次"
                    prop="roundNum"
                ></el-table-column>
                <el-table-column
                    label="排名"
                    prop="ranking"
                    width="100px"
                ></el-table-column>
                <el-table-column label="积分" prop="integral">
                    <template slot-scope="scope">
                        <span>{{
                            scope.row.integral ? scope.row.integral : "0"
                        }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="签到状态" prop="sign">
                    <template slot-scope="scope">
                        <el-tag
                            type="warning"
                            v-if="scope.row.sign === 0"
                            size="mini"
                            >未签到</el-tag
                        >
                        <el-tag
                            type="success"
                            v-else-if="scope.row.status === 1"
                            size="mini"
                            >已签到</el-tag
                        >
                        <span v-else>未签到</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="changeIntegral(scope.row)"
                            >积分修改</el-button
                        >
                        <el-button
                            type="text"
                            size="small"
                            @click="resetClicked(scope.row)"
                            >重置选手</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
        <!-- 重置考生信息 -->
        <el-dialog
            title="修改积分"
            :visible.sync="changeIntegralDialogVisible"
            width="40%"
            @close="changeIntegralCloseDialog"
        >
            <el-form
                :model="resetStudentsForm"
                label-width="120px"
                ref="resetStudentsRef"
                :rules="resetStudentsRules"
            >
                <el-row>
                    <el-col :span="20">
                        <el-form-item label="积分" prop="integral">
                            <el-input
                                v-model="resetStudentsForm.integral"
                                placeholder="请输入"
                                type="text"
                                clearable
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="changeIntegralDialogVisible = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="resetPlayerInfo"
                    >确 定</el-button
                >
            </span>
        </el-dialog>
    </div>
</template>

<script>
import {
  getPczxRoominfo,
  getPczxRoomSit,
  removeSitById,
  updateIntegral
} from '@/http/api'

export default {
  data () {
    return {
      changeIntegralDialogVisible: false,
      roomInfo: {},
      resetStudentsForm: {
        integral: 0,
        sitId: null
      },
      resetStudentsRules: {
        integral: [{ required: true, message: '请输入', trigger: 'blue' }]
      },
      gameType: '',
      dict_signIn: this.$userInfo.dict_signIn(),
      dict_gameType: this.$userInfo.getDataList(this, 'pczx_match_type').then(value => { this.dict_gameType = value }),
      queryForm: {
        pageNum: 1,
        pageSize: 100,
        phone: '',
        sign: '',
        userName: '',
        roomId: this.$route.query.roomId
      },
      dataList: []
    }
  },
  created () {
    this.getRoomDetail()
  },
  methods: {
    reset () {
      this.$refs.queryFormRef.resetFields()
    },
    changeIntegralCloseDialog () {
      this.$refs.resetStudentsForm.resetFields()
    },
    getRoomDetail () {
      getPczxRoominfo({ id: this.queryForm.roomId }).then((res) => {
        this.roomInfo = res.data
        this.gameType = this.getGameType(res.data.type)
        this.getPlayerList()
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 获取选手信息
    getPlayerList () {
      getPczxRoomSit(this.queryForm).then((res) => {
        this.dataList = res.data.list
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 修改积分点击事件
    changeIntegral (rowData) {
      this.resetStudentsForm.sitId = rowData.sitId
      this.changeIntegralDialogVisible = true
    },
    // 修改积分确定
    resetPlayerInfo () {
      this.$refs.resetStudentsRef.validate(async valid => {
        if (!valid) return
        updateIntegral(this.resetStudentsForm).then((res) => {
          this.changeIntegralDialogVisible = false
          this.$message.success('修改成功')
          this.getPlayerList()
        }).catch((err) => {
          console.log('err', err)
        })
      })
    },
    // 重置选手点击事件
    resetClicked (rowData) {
      this.$confirm('是否重置该学生?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        removeSitById({ id: rowData.id }).then(res => {
          this.$message.success('已重置')
          this.getPlayerList()
        })
      }).catch(() => {
      })
    },
    getGameType (gameType) {
      var temp = '未设置'
      for (const key in this.dict_gameType) {
        if (Object.hasOwnProperty.call(this.dict_gameType, key)) {
          const element = this.dict_gameType[key]
          if (element.dictValue === gameType) {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    goBack () {
      this.$router.back()
    }
  }
}
</script>

<style>
</style>
